<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col cols="12" class="text-h6 pb-0">
        <span>{{ label }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <calendar-input
          v-model="formData.startDate"
          :label="$trans('date_day_from')"
          :rules="[rules.required]"
          :disabled="
            disabledForm || eventUpdateSingleRecurringBookingFromGroupMode
          "
        />
      </v-col>
      <v-col v-if="!formData.allDay" cols="6">
        <c-time-input
          v-model="formData.startTime"
          :label="$trans('date_hour_from')"
          :rules="formData.allDay ? [] : [rules.required]"
          :disabled="
            disabledForm || eventUpdateSingleRecurringBookingFromGroupMode
          "
          type="from"
          @input="formData.startTime = $event"
        />
      </v-col>
      <template v-if="formData.allDay || formData.wantsSetEndTime">
        <v-col cols="6">
          <calendar-input
            v-model="formData.endDate"
            :label="$trans('date_day_to')"
            :rules="[rules.required]"
            :disabled="
              disabledForm || eventUpdateSingleRecurringBookingFromGroupMode
            "
            :min="formData.startDate"
          />
        </v-col>
        <v-col v-if="!formData.allDay" cols="6">
          <c-time-input
            v-model="formData.endTime"
            :label="$trans('date_hour_to')"
            :rules="formData.allDay ? [] : [rules.required]"
            :disabled="
              disabledForm || eventUpdateSingleRecurringBookingFromGroupMode
            "
            :min="minEndTime"
            type="to"
            @input="formData.endTime = $event"
          />
        </v-col>
      </template>
      <v-col cols="12" class="pt-0 mt-0 pb-0 mb-0 d-flex align-center">
        <v-checkbox
          v-model="formData.allDay"
          dense
          class="mt-0"
          :disabled="
            disabledForm || eventUpdateSingleRecurringBookingFromGroupMode
          "
          hide-details
        >
          <template #label>
            <v-tooltip right max-width="350">
              <template #activator="{ on }">
                <div v-on="on">
                  <span>{{ $trans("all_day") }}</span>
                </div>
              </template>
              {{ $trans("all_day_tooltip") }}
            </v-tooltip>
          </template>
        </v-checkbox>
      </v-col>
      <v-col
        v-if="!initialWantsSetEndTime && !formData.allDay"
        cols="12"
        class="pt-0 mt-0 d-flex align-center"
      >
        <v-checkbox
          v-model="formData.wantsSetEndTime"
          dense
          class="mt-0"
          :disabled="
            disabledForm || eventUpdateSingleRecurringBookingFromGroupMode
          "
          hide-details
        >
          <template #label>
            <v-tooltip right max-width="350">
              <template #activator="{ on }">
                <div v-on="on">
                  <span>{{ $trans("wants_set_end_time_label") }}</span>
                </div>
              </template>
              {{ $trans("wants_set_end_time_info") }}
            </v-tooltip>
          </template>
        </v-checkbox>
      </v-col>
      <v-col v-if="!hideAvailability" cols="12">
        <employee-booking-availability-wrapper
          v-if="
            formData.service &&
            !formData.allDay &&
            !eventUpdateSingleRecurringBookingFromGroupMode
          "
          :disabled="disabledForm"
          :event-update-mode="eventUpdateMode"
          :start-time.sync="formData.startTime"
          :service="formData.service"
          :employee="formData.employee"
          :location="formData.serviceLocation"
          :start-date="formData.startDate"
          @update="handleEmployeeAvailabilityUpdate"
          @updateDate="handleEmployeeAvailabilityDateUpdate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CTimeInput from "@/views/dashboard/pages/Calendar/components/CTimeInput.vue";
import CalendarInput from "@/lib/calendesk-js-library/components/CalendarInput.vue";
import EmployeeBookingAvailabilityWrapper from "@/views/dashboard/pages/Calendar/components/ManageEvent/components/EmployeeBookingAvailabilityWrapper.vue";
import {
  maxNumber,
  minNumber,
  required,
  requiredArray,
} from "@/lib/calendesk-js-library/forms/validators";

export default {
  components: { EmployeeBookingAvailabilityWrapper, CalendarInput, CTimeInput },
  props: {
    form: {
      type: Object,
      required: true,
    },
    disabledForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    eventUpdateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    eventUpdateSingleRecurringBookingFromGroupMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideAvailability: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialWantsSetEndTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formData: this.$helpers.cloneObject(this.form),
      rules: {
        required,
        maxNumber,
        minNumber,
        requiredArray,
      },
    };
  },
  computed: {
    minEndTime() {
      if (
        this.$moment(this.formData.endDate).isAfter(
          this.$moment(this.formData.startDate),
        )
      ) {
        return undefined;
      }

      return this.$moment(this.formData.startTime, this.$helpers.timeFormat)
        .add(1, "minutes")
        .format(this.$helpers.timeFormat);
    },
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        if (JSON.stringify(this.formData) !== JSON.stringify(value)) {
          this.formData = this.$helpers.cloneObject(value);
        }

        this.fixDates();
      },
    },
    "formData.wantsSetEndTime"() {
      this.fixDates();
    },
    formData: {
      deep: true,
      handler() {
        if (JSON.stringify(this.formData) !== JSON.stringify(this.form)) {
          this.fixDates();
          this.$emit("update", this.$helpers.cloneObject(this.formData));
        }
      },
    },
  },
  created() {
    this.formData.wantsSetEndTime =
      this.form.wantsSetEndTime || this.initialWantsSetEndTime;
  },
  methods: {
    fixDates() {
      if (this.formData.startDate) {
        const startDateTime = this.$moment(
          this.formData.startDate +
            " " +
            (this.formData.allDay ? "00:00" : this.formData.startTime),
          this.$helpers.dateTimeFormat,
        );

        // Determine if we need to check/adjust the end date and time
        const hasEndDate = !!this.formData.endDate;

        let endDateTime;
        if (hasEndDate) {
          endDateTime = this.$moment(
            this.formData.endDate +
              " " +
              (this.formData.allDay ? "00:00" : this.formData.endTime),
            this.$helpers.dateTimeFormat,
          );
        }

        const startTimeIsAfterEndTime =
          hasEndDate && startDateTime.isAfter(endDateTime);

        // Determine if we need to adjust the end date/time
        const shouldAdjustEndTime =
          !this.formData.wantsSetEndTime ||
          !hasEndDate ||
          startTimeIsAfterEndTime;

        if (shouldAdjustEndTime) {
          if (this.formData.allDay) {
            // For all-day events, if end date isn't set or is invalid, set it to start date
            if (!hasEndDate || startTimeIsAfterEndTime) {
              // For new all-day events or invalid end dates, default to same day
              this.formData.endDate = startDateTime.format(
                this.$helpers.dateFormat,
              );
              this.formData.endTime = "00:00";
            } else {
              // Keep existing end date but ensure time is 00:00
              this.formData.endTime = "00:00";
            }
          } else {
            // For regular events, calculate based on service duration
            let duration = 60; // Default duration in minutes

            if (this.formData.service) {
              if (this.formData.service.selectedType) {
                duration = this.formData.service.selectedType.duration;
              } else {
                duration = this.formData.service.duration;
              }
            }

            // Add duration to start time
            const calculatedEndDateTime = startDateTime
              .clone()
              .add(duration, "minutes");
            this.formData.endTime = calculatedEndDateTime.format(
              this.$helpers.timeFormat,
            );
            this.formData.endDate = calculatedEndDateTime.format(
              this.$helpers.dateFormat,
            );
          }
        }
      }
    },
    handleEmployeeAvailabilityUpdate(data) {
      this.formData.startDate = data.startDate;
      this.formData.endDate = data.endDate;
      this.formData.startTime = data.startTime;
      this.formData.endTime = data.endTime;
      this.formData.employee = data.employee;
    },
    handleEmployeeAvailabilityDateUpdate(data) {
      this.formData.startDate = data.startDate;
      this.formData.endDate = data.endDate;
      this.formData.employee = data.employee;
    },
  },
};
</script>
